<template>
  <PrismEditor
    class="editor"
    v-model="renderCode"
    :highlight="highlighter"
    line-numbers
  />
</template>

<script lang="ts">
import { Vue, Component, PropSync, Watch } from "vue-property-decorator";
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css";

import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-tomorrow.css";

@Component({
  components: {
    PrismEditor,
  },
})
export default class CodeEditor extends Vue {
  @PropSync("code")
  inCode!: string;

  @Watch("inCode")
  onInCodeChanged() {
    this.renderCode = this.inCode;
  }

  renderCode: any = 'console.log("Hello World")';

  @Watch("renderCode")
  onRenderCodeChanged() {
    this.inCode = this.renderCode;
  }

  highlighter(code: any) {
    return highlight(code, languages.js);
  }

  mounted() {
    this.renderCode = this.inCode;
  }
}
</script>

<style scoped>
.editor {
  min-height: 100%;
  width: 100%;

  background: #2d2d2d;
  color: #ccc;

  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
</style>
