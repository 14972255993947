<template>
  <!-- <div class="main-container">
    <div class="topic">
      Script Editor
    </div> -->
  <div class="editor-contrainer">
    <div class="topic">Script Editor</div>
    <div class="script-list" :class="{ 'script-list-collapsed': collapsed }">
      <div class="collapsed-button" @click="toggleCollapsed">
        <a-icon :type="collapsed ? 'menu-unfold' : 'menu-fold'" />
      </div>
      <a-menu v-show="!collapsed" :default-selected-keys="['1']" :default-open-keys="['sub1']" mode="inline" theme="dark">
        <a-menu-item v-for="(data, index) in scripts" :key="index" @click="onItemClicked(data)">
          <span>{{ data.name }}</span>
        </a-menu-item>
      </a-menu>
    </div>

    <div class="title-bar">
      {{ selectedScript ? `Script Name : ${selectedScript.name}` : 'Script Name :' }}
    </div>

    <div class="code-editor">
      <CodeEditor :code.sync="code" />
    </div>

    <div v-if="selectedScript" class="button-affix">
      <a-popconfirm placement="leftBottom" ok-text="Yes" cancel-text="No" @confirm="updateScript">
        <template slot="title">
          <p>Do you want to update the script?</p>
        </template>
        <a-tooltip placement="top">
          <template slot="title">
            <span>Save</span>
          </template>
          <a-button type="primary" shape="circle" icon="save" size="large" />
        </a-tooltip>
      </a-popconfirm>
    </div>
  </div>
  <!-- </div> -->
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import CodeEditor from './CodeEditor.vue';
import { NODE_ENV } from '../systems/configurations';

import axios from 'axios';

@Component({
  components: { CodeEditor },
})
export default class ScriptEditor extends Vue {
  //#region authenticate
  async getAccessToken() {
    let accessToken = null;

    if (NODE_ENV !== 'development') {
      try {
        accessToken = await this.$auth.getTokenSilently();
      } catch (err) {
        if (err instanceof Error) {
          this.$notification['error']({
            message: err.message,
            description: '',
            placement: 'bottomRight',
          });
        }
      }
    }

    return accessToken;
  }
  //#endregion

  collapsed: boolean = false;
  toggleCollapsed() {
    this.collapsed = !this.collapsed;
  }

  code: string = '';

  selectedScript: any = null;
  scripts: any[] = [];

  @Prop()
  module: any;

  openNotification(message: any) {
    this.$notification['success']({
      message: message,
      description: '',
      placement: 'bottomRight',
    });
  }

  async updateScript() {
    if (!this.selectedScript) {
      this.$notification['warning']({
        message: 'There is no a selected script.',
        description: '',
        placement: 'bottomRight',
      });
      return;
    }

    let updateData = {
      _id: this.selectedScript._id,
      name: this.selectedScript.name,
      content: this.code,
      isActive: true,
    };

    const accessToken = await this.getAccessToken();

    try {
      let res = await axios.patch(
        this.module.patch.url,
        updateData,
        accessToken
          ? {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          : undefined
      );

      this.$notification['success']({
        message: 'Update Completed.',
        description: '',
        placement: 'bottomRight',
      });
      this.fetchScripts();
    } catch (err) {
      if (err instanceof Error) {
        this.$notification['error']({
          message: err.message,
          description: '',
          placement: 'bottomRight',
        });
      }
    }
  }

  onItemClicked(data: any) {
    this.selectedScript = data;
    this.code = this.selectedScript.content;
  }

  async fetchScripts() {
    const accessToken = await this.getAccessToken();

    try {
      let res = await axios.get(
        this.module.get.url,
        accessToken
          ? {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          : undefined
      );
      this.scripts = res.data;
    } catch (err) {
      if (err instanceof Error) {
        this.$notification['error']({
          message: err.message,
          description: '',
          placement: 'bottomRight',
        });
      }
    }
  }

  mounted() {
    this.fetchScripts();
  }
}
</script>

<style lang="scss" scoped>
$script-list-color: #3a3a3a;

.script-list {
  grid-row: 2 / span 2;
  width: 400px;
  height: 100%;
  transition: width 0.4s;
  background-color: $script-list-color;
  overflow: auto;

  ul {
    background-color: $script-list-color;

    li {
      text-align: left;
      vertical-align: middle;

      span {
        color: white;
        font-size: 12px;
      }
    }
  }
}

.topic {
  grid-column: 1 / span 2;
  margin: 12px 8px;
  text-align: left;
  font-size: 24px;
  font-family: Verdana, Geneva, sans-serif;
}

.script-list-collapsed {
  width: 60px;
}

.collapsed-button {
  background-color: $script-list-color;
  color: white;
  padding: 10px 20px;
  font-size: 20px;
  height: 50px;
  text-align: right;
}

.editor-contrainer {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto 1fr;
  height: 100%;
  padding: 20px 35px;
}

.title-bar {
  background-color: #444444;
  color: white;
  text-align: left;
  padding-left: 10px;
  font-size: 14px;
}

.code-editor {
  overflow: auto;
}

.button-affix {
  position: absolute;
  bottom: 30px;
  right: 50px;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: gray;
  // border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}
</style>
