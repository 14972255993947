<template>
  <div v-if="innerSelectedModule">
    <!-- Add Flow Modal -->
    <a-modal width="70%" height="80%" v-model="isAddFlowModalShown" title="Add Flow" @ok="confirmAddChart">
      <a-row type="flex" :gutter="16" style="width: 100%">
        <a-col style="width: 40%">
          <a-collapse>
            <a-collapse-panel v-for="item in innerSelectedModule.flowUI.information" :key="item.header" :header="item.header">
              <pre style="white-space: pre-wrap">
                {{ item.content }}
              </pre>
            </a-collapse-panel>
          </a-collapse>
        </a-col>
        <a-col style="width: 60%; height: 100%">
          <p>
            <a-input placeholder="Step Type" v-model="addFlowType" />
          </p>
          <p>
            <a-input placeholder="Description" v-model="addStepDesc" />
          </p>
          <p>
            <a-textarea v-model="addFlowArgs" placeholder="Arguments" :auto-size="{ minRows: 8, maxRows: 12 }" />
          </p>
          <a-textarea placeholder="Script Formatter" v-model="stringEscapePost" :autoSize="{ minRows: 5, maxRows: 8 }" />
          <a-button type="link" size="small" @click="onStringEscapePostClicked"> Format </a-button>
        </a-col>
      </a-row>
    </a-modal>
    <!-- Edit Flow Modal -->
    <a-modal width="600px" v-model="isEditFlowModalShown" title="Edit Flow" @ok="confirmEditChart">
      <p>
        <a-input placeholder="Step Type" v-model="editFlowType" disabled />
      </p>
      <p>
        <a-input placeholder="Description" v-model="editStepDesc" />
      </p>
      <p>
        <a-textarea v-model="editFlowArgs" placeholder="Arguments" :auto-size="{ minRows: 3, maxRows: 10 }" />
      </p>
      <CodeEditor :code.sync="editStepScript" style="height: 240px" v-if="editStepScript" />
    </a-modal>
    <div class="description">
      <div class="description-label">Description</div>
      <a-input v-model="flowData.description" placeholder="Workflow Description" />
    </div>
    <div class="button-list">
      <a-button size="small" type="link" class="collapse-button" @click="showAddFlowModal"> Add </a-button>
      <a-button size="small" type="link" class="collapse-button" @click="showEditFlowModal"> Edit </a-button>
      <a-divider type="vertical" class="button-list-separator" />
      <a-button size="small" type="link" class="collapse-button" @click="removeChart"> Delete </a-button>
      <a-divider type="vertical" class="button-list-separator" />
      <a-button size="small" type="link" class="collapse-button" @click="rearrangeChart"> Re-Arrange </a-button>
      <a-divider type="vertical" class="button-list-separator" />
      <a-button size="small" type="link" class="collapse-button" @click="callPostAPI"> Create </a-button>
      <a-button size="small" type="link" class="collapse-button" @click="callPatchAPI"> Update </a-button>
      <a-divider type="vertical" class="button-list-separator" />
      <a-button size="small" type="link" class="collapse-button" @click="openFileSelector"> Import </a-button>
      <a-button size="small" type="link" class="collapse-button" @click="exportFlow"> Export </a-button>
    </div>
    <rearrangeable-flow-chart
      id="arrange-chart"
      :nodes.sync="innerNodes"
      :connections.sync="innerConnections"
      :readonly="readonly"
      @addChart="setAddChart"
      @currentNode="setGetCurrentNode"
      @rearrangeChart="setRearrangeChart"
      @removeChart="setRemoveChart"
      @updateChartData="setUpdateChartData"
    />
    <input ref="importFile" type="file" @change="importFileHandler" hidden />
  </div>
</template>

<script lang="ts">
import axios from 'axios';
import jsec from 'jsesc';
import beautify from 'json-beautify';
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';

import RearrangeableFlowChart from '../components/RearrangeableFlowChart.vue';

import { ModuleConfigModel } from '../configs/interfaces/IApiConfig';
import CodeEditor from './CodeEditor.vue';

import { NODE_ENV } from '../systems/configurations';

@Component({
  components: {
    RearrangeableFlowChart,
    CodeEditor,
  },
})
export default class RearrangeableFlowChartWithControl extends Vue {
  //#region authenticate
  async getAccessToken() {
    let accessToken = null;
    if (NODE_ENV !== 'development') {
      try {
        accessToken = await this.$auth.getTokenSilently();
      } catch (err) {
        if (err instanceof Error) {
          this.$notification['error']({
            message: err.message,
            description: '',
            placement: 'bottomRight',
          });
        }
      }
    }

    return accessToken;
  }
  //#endregion

  innerSelectedModule: ModuleConfigModel | null = null;

  get readonly(): boolean {
    return this.isAddFlowModalShown || this.isEditFlowModalShown;
  }

  @Prop()
  selectedModule!: ModuleConfigModel;

  @Watch('selectedModule')
  onSelectedModuleChanged() {
    this.innerSelectedModule = this.selectedModule;
  }

  flowBody: string = JSON.stringify({});
  flowData: any = {
    description: '',
    steps: [],
  };

  @Watch('flowData.steps')
  onStepsChanged(val: any) {
    let temp = Object.assign({}, this.flowData);
    this.flowBody = this.formatJSON(JSON.stringify(temp));
  }

  postBody: string = JSON.stringify({});
  patchBody: string = JSON.stringify({});

  @Prop()
  data!: any;

  @Watch('data')
  onDataChanged(val: any) {
    this.updateFlowBody(val);
    this.$nextTick(() => {
      this.rearrangeChart();
    });
  }

  innerNodes: any[] = [
    {
      id: 0,
      x: 140,
      y: 50,
      name: 'Start',
      type: 'start',
      height: 40,
    },
  ];
  innerConnections: any[] = [];

  @Prop()
  createdCallBack!: Function;

  @Prop()
  updatedCallBack!: Function;

  stringEscapePost: string = '';

  isAddFlowModalShown = false;
  addFlowType = '';
  addFlowArgs = '';
  addStepDesc = '';

  isEditFlowModalShown = false;
  editStepDesc = '';
  editStepScript: string | undefined = '';
  editFlowType: string = '';
  editFlowArgs: any = null;
  editingNode: any = null;

  getResData: any = null;

  addChart: Function = Function;
  setAddChart(data: any) {
    this.addChart = data;
  }

  getCurrentNode: Function = Function;
  setGetCurrentNode(data: any) {
    this.getCurrentNode = data;
  }

  removeChart: Function = Function;
  setRemoveChart(data: any) {
    this.removeChart = data;
  }

  rearrangeChart: Function = Function;
  setRearrangeChart(data: any) {
    this.rearrangeChart = data;
  }

  updateChartData: Function = Function;
  setUpdateChartData(data: any) {
    this.updateChartData = data;
  }

  confirmAddChart() {
    this.addChart(this.addFlowType, JSON.parse(this.addFlowArgs), [
      {
        name: this.addStepDesc,
        value: this.addStepDesc,
      },
    ]);
    this.isAddFlowModalShown = false;
  }

  showAddFlowModal() {
    this.isAddFlowModalShown = true;
  }

  async showEditFlowModal() {
    const currentNode = this.getCurrentNode();

    this.editFlowType = currentNode.name;
    this.editStepDesc = currentNode.content;
    this.editFlowArgs = beautify(currentNode.description, null, 4, 80);
    this.editingNode = currentNode.rawNode;

    if (currentNode.description && currentNode.description.scriptContent) {
      this.editStepScript = currentNode.description.scriptContent;
    } else if (currentNode.description && currentNode.description.scriptId && this.selectedModule && this.selectedModule.extraGetApi) {
      const accessToken = await this.getAccessToken();
      axios
        .get(
          `${this.selectedModule.extraGetApi[0].url}/${currentNode.description.scriptId}`,
          accessToken
            ? {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              }
            : undefined
        )
        .then((res) => {
          if (res.data) {
            this.editStepScript = res.data.content;
          }
        })
        .catch((err) => {});
    } else {
      this.editStepScript = undefined;
    }
    this.isEditFlowModalShown = true;
  }

  updateFlowBody(data: any) {
    this.flowBody = this.formatJSON(data);
    let json = JSON.parse(this.flowBody);
    this.flowData = json;

    this.innerNodes = [
      {
        id: 0,
        x: 0,
        y: 50,
        name: 'Start',
        type: 'start',
      },
    ];
    this.innerConnections = [];

    if (!json || !json.steps) {
      return;
    }

    const flowSteps = json.steps;
    for (let i = 0; i < flowSteps.length; i++) {
      const step = flowSteps[i];
      this.innerNodes.push({
        id: step._id,
        x: 0,
        y: 0,
        name: step.type,
        description: step.args,
        value: step.args,
        approvers: [
          {
            name: step.description,
            value: step.description,
          },
        ],
      });
      this.innerConnections.push({
        source: {
          id: step.parentId ? step.parentId : 0,
          position: 'right',
        },
        destination: {
          id: step._id,
          position: 'left',
        },
        id: i,
        type: 'pass',
      });
    }
  }

  formatJSON(jsonString: string) {
    let json = JSON.parse(jsonString);
    return beautify(json, null, 4, 80);
  }

  updateJson() {
    if (this.innerSelectedModule && this.innerSelectedModule.flowUI && this.innerSelectedModule.flowUI.sortBy && this.innerSelectedModule.flowUI.stepBy) {
      let steps = [];
      for (let i = 0; i < this.innerNodes.length; i++) {
        const node = this.innerNodes[i];
        if (node.type == 'start' || node.type == 'end') {
          continue;
        }

        const parentNode = this.innerConnections.find((e) => node.id == e.destination.id);
        const parentId = parentNode ? (parentNode.source.id == 0 ? null : parentNode.source.id) : null;

        steps.push({
          _id: node.id,
          parentId: parentId,
          type: node.name,
          description: node.approvers[0].value,
          args: node.description,
        });
      }

      this.flowData[this.innerSelectedModule.flowUI.stepBy] = steps;
    }
  }

  confirmEditChart() {
    if (this.editingNode) {
      this.editingNode.name = this.editFlowType;

      let editArgs = JSON.parse(this.editFlowArgs);

      if (this.editStepScript) {
        editArgs = Object.assign({}, editArgs, {
          scriptContent: this.editStepScript,
        });
      }

      this.editingNode.description = editArgs;

      this.editingNode.approvers = [
        {
          name: this.editStepDesc,
          value: this.editStepDesc,
        },
      ];
    }
    this.isEditFlowModalShown = false;
  }

  onStringEscapePostClicked() {
    this.stringEscapePost = jsec(this.stringEscapePost, {
      json: true,
    });
  }

  callPostAPI() {
    if (!this.selectedModule || !this.selectedModule.post) {
      return;
    }

    this.updateChartData();
    this.$nextTick(() => {
      this.updateJson();

      let postBody = this.formatJSON(JSON.stringify(this.flowData));

      if (!this.selectedModule.post || !this.selectedModule.post.url) {
        this.$notification['warning']({
          message: 'Post URL not be set',
          description: '',
          placement: 'bottomRight',
        });
        return;
      }

      this.callPostAPICore(this.selectedModule.post.url, JSON.parse(postBody), 'Created data successfully', 'Creating data failed');
    });
  }

  async callPostAPICore(url: string, postBody: any, successMessage: string, errorMessage: string) {
    const accessToken = await this.getAccessToken();
    axios
      .post(
        url,
        postBody,
        accessToken
          ? {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          : undefined
      )
      .then((res) => {
        this.createdCallBack();
        this.$notification['success']({
          message: successMessage,
          description: '',
          placement: 'bottomRight',
        });
      })
      .catch((err) => {
        console.log(err);
        this.$notification['error']({
          message: errorMessage,
          description: err.response.data.errorMessage ?? err.message,
          placement: 'bottomRight',
        });
      });
  }

  callPatchAPI() {
    if (!this.selectedModule || !this.selectedModule.patch) {
      return;
    }

    this.updateChartData();
    this.$nextTick(() => {
      this.updateJson();
      let patchBody = this.formatJSON(JSON.stringify(this.flowData));

      if (!this.selectedModule.patch || !this.selectedModule.patch.url) {
        this.$notification['warning']({
          message: 'Patch URL not be set',
          description: '',
          placement: 'bottomRight',
        });
        return;
      }

      this.callPatchAPICore(this.selectedModule.patch.url, JSON.parse(patchBody), 'Updated data successfully', 'Updating data failed');
    });
  }

  async callPatchAPICore(url: string, patchBody: any, successMessage: string, errorMessage: string) {
    const accessToken = await this.getAccessToken();
    axios
      .patch(
        url,
        patchBody,
        accessToken
          ? {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          : undefined
      )
      .then((res) => {
        this.updatedCallBack();
        this.$notification['success']({
          message: successMessage,
          description: '',
          placement: 'bottomRight',
        });
      })
      .catch((err) => {
        console.log('addFlowType', this.addFlowType);
        this.$notification['error']({
          message: errorMessage,
          description: err.response.data.errorMessage ?? err.message,
          placement: 'bottomRight',
        });
      });
  }

  async exportFlow() {
    this.updateJson();
    let flowBody = JSON.parse(this.formatJSON(JSON.stringify(this.flowData)));
    let exportData = { ...flowBody };

    delete exportData.id;
    delete exportData._id;

    const exportFlow: Array<any> = [];
    for (let i = 0; i < flowBody.steps.length; i++) {
      const step = flowBody.steps[i];
      let stepData = { ...step };

      if (step.args && step.args.scriptId && this.selectedModule && this.selectedModule.extraGetApi) {
        try {
          const accessToken = await this.getAccessToken();
          const res = await axios.get(
            `${this.selectedModule.extraGetApi[0].url}/${step.args.scriptId}`,
            accessToken
              ? {
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                  },
                }
              : undefined
          );

          if (res.data) {
            stepData.args.scriptContent = res.data.content;
            delete stepData.args.scriptId;
          }
        } catch (err) {}
      }
      exportFlow.push(stepData);
    }

    this.serializeStepId(exportFlow);
    exportData.steps = exportFlow;

    this.saveFile(this.formatJSON(JSON.stringify(exportData)), `${exportData.description}.json`);
  }

  serializeStepId(steps: Array<any>) {
    let idCounter = 0;

    for (let i = 0; i < steps.length; i++) {
      const step = steps[i];
      const currentId = step._id;

      if (currentId) {
        for (let j = 0; j < steps.length; j++) {
          const childStep = steps[j];

          if (childStep.parentId == step._id) {
            childStep.parentId = 'step_' + idCounter;
          }
        }
      }
      step._id = 'step_' + idCounter++;
    }
  }

  saveFile(data: string, filename: string) {
    const blob = new Blob([data], { type: 'text/plain' });
    const e = document.createEvent('MouseEvents'),
      a = document.createElement('a');
    a.download = filename;
    a.href = window.URL.createObjectURL(blob);
    a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
    e.initEvent('click', true, false);
    a.dispatchEvent(e);
  }

  openFileSelector() {
    const importFileElement = this.$refs.importFile as HTMLElement;

    if (importFileElement) {
      importFileElement.click();
    }
  }

  importFileHandler(e: any) {
    const fileReader = new FileReader();

    fileReader.onload = (res: any) => {
      this.importFlow(res.target.result);
    };
    fileReader.readAsText(e.target.files[0]);
  }

  importFlow(fileContent: string) {
    try {
      const jsonContent = JSON.parse(fileContent);

      if (this.flowData._id) {
        jsonContent._id = this.flowData._id;
      }

      this.updateFlowBody(JSON.stringify(jsonContent));
      this.$nextTick(() => this.rearrangeChart());
    } catch (err) {
      console.log(err);
    }
  }

  mounted() {
    this.innerSelectedModule = this.selectedModule;
  }
}
</script>

<style lang="scss" scoped>
.description {
  display: grid;
  grid-template-columns: auto 1fr;
  text-align: center;
  margin-bottom: 8px;
}

.description-label {
  padding-top: 6px;
  padding-bottom: 4px;
  margin-right: 8px;
}

.button-list {
  text-align: left;
}
</style>
