<template>
  <div>
    <div class="topic">Synchronizer</div>
    <div class="description">
      <a-descriptions>
        <a-descriptions-item label="Sync only unpaired data" :span="3"> syncType=UNPAIRED&systemCode=HIVEGRID&systemModuleCode=ORGANIZATIONS </a-descriptions-item>

        <a-descriptions-item label="Sync all data" :span="3"> syncType=ALL&systemCode=HIVEGRID&systemModuleCode=ORGANIZATIONS(&updatedDateField=updatedDate) </a-descriptions-item>
      </a-descriptions>
    </div>
    <div class="sync-body">
      <a-input placeholder="Query strings" v-model="query" />
      <a-textarea v-model="body" placeholder="Filtering conditions" :auto-size="{ minRows: 3, maxRows: 5 }" @blur="onBodyBlur" />
      <div class="button-container">
        <a-button type="primary" :loading="loading" @click="callSync"> Sync Now </a-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import axios from 'axios';
import beautify from 'json-beautify';

import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

import { ModuleConfigModel } from '../configs/interfaces/IApiConfig';
import { NODE_ENV } from '../systems/configurations';

@Component({})
export default class Synchronizer extends Vue {
  //#region authenticate
  async getAccessToken() {
    let accessToken = null;
    if (NODE_ENV !== 'development') {
      try {
        accessToken = await this.$auth.getTokenSilently();
      } catch (err) {
        if (err instanceof Error) {
          this.$notification['error']({
            message: err.message,
            description: '',
            placement: 'bottomRight',
          });
        }
      }
    }

    return accessToken;
  }
  //#endregion

  loading: boolean = false;

  query: string = '';
  body: string = '';

  @Prop()
  selectedModule!: ModuleConfigModel;

  @Prop()
  queryObject: any;

  @Watch('queryObject')
  onSelectedRowDataChanged(val: any) {
    console.log(val);
    this.query = `systemCode=${val.sourceSystemCode}&systemModuleCode=${val.sourceSystemModuleCode}`;
  }

  @Prop()
  callBack!: Function;

  formatJSON(jsonString: string) {
    let json = JSON.parse(jsonString);
    return beautify(json, null, 4, 80);
  }

  onBodyBlur() {
    this.body = this.formatJSON(this.body);
  }

  async callSync() {
    if (this.selectedModule.post) {
      const accessToken = await this.getAccessToken();
      try {
        this.loading = true;
        axios
          .post(
            `${this.selectedModule.post.url}${this.query ? `?` : ``}${this.query}`,
            JSON.parse(this.body),
            accessToken
              ? {
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                  },
                }
              : undefined
          )
          .then((res) => {
            this.$notification['success']({
              message: 'Synced data successfully',
              description: '',
              placement: 'bottomRight',
            });
          })
          .catch((err) => {
            this.$notification['error']({
              message: 'Syncing data failed',
              description: err.response.data.errorMessage ?? err.message,
              placement: 'bottomRight',
            });
          })
          .finally(() => {
            this.callBack();
            this.loading = false;
          });
      } catch (err) {
        this.loading = false;
      }
    }
  }
}
</script>

<style scoped>
.button-container {
  text-align: left;
}

.sync-body > *:not(:last-child) {
  display: block;
  margin-bottom: 8px;
}

.topic {
  margin: 12px 8px 12px 0px;
  text-align: left;
  font-size: 24px;
  font-family: Verdana, Geneva, sans-serif;
}

.description {
  text-align: left;
  display: block;
}
</style>
