<template>
  <div>
    <flow-chart
      class="flowchart"
      :style="{ height: autoHeight + 'px' }"
      :nodes="renderNodes"
      :connections="renderConnections"
      @editnode="handleEditNode"
      ref="chart"
      @dblclick="updateFlowChartHeight"
      :readonly="readonly"
    >
    </flow-chart>
  </div>
</template>

<script lang="ts">
import {
  Component,
  PropSync,
  Watch,
  Vue,
  Emit,
  Prop
} from "vue-property-decorator";
import FlowChart from "flowchart-vue";
import beautify from "json-beautify";

@Component({
  components: {
    FlowChart
  }
})
export default class RearrangeableFlowChart extends Vue {
  flowChart: any;

  chartUpdateWaiting = false;

  selectedEditingNode: any = null;

  renderNodes: any[] = [];
  renderConnections: any[] = [];

  autoHeight: Number = 600;

  @Prop({ default: false })
  readonly!: boolean;

  @Watch("readonly")
  test(val: any) {
    console.log(val);
  }

  @Watch("renderNodes")
  onRenderNodesChanged() {
    this.inNodes = this.renderNodes;
  }

  @Watch("renderConnections")
  onRednerConnectionsChanged() {
    this.inConnections = this.renderConnections;
  }

  @PropSync("nodes", { default: [] })
  inNodes!: any[];

  @Watch("inNodes")
  onInNodesChanged() {
    this.renderNodes = this.inNodes;
  }

  @PropSync("connections", { default: [] })
  inConnections!: any[];

  @Watch("inConnections")
  onInConnectionsChanged() {
    this.renderConnections = this.inConnections;
  }

  @Emit("removeChart")
  emitRemoveChart() {
    return this.flowChart.remove;
  }

  addChart(name: string, description: string, approvers: any[]) {
    this.updateChartData();
    this.renderNodes.push({
      id: +new Date(),
      x: 10,
      y: 10,
      name: name,
      description: description,
      type: "operation",
      approvers: approvers
    });
  }

  @Emit("addChart")
  emitAddChart() {
    return this.addChart;
  }

  getCurrentNode() {
    this.flowChart.editCurrent();
    return this.selectedEditingNode;
  }

  @Emit("currentNode")
  emitGetCurrentNode() {
    return this.getCurrentNode;
  }

  // draw the input node at position (x, y)
  drawNodes(
    nodeId: any,
    positionX: any,
    positionY: any,
    offsetX: any,
    offsetY: any
  ) {
    // draw node at (x, y) position
    let node = this.renderNodes.find(e => e.id == nodeId);
    node.y = positionY;
    node.x = positionX;

    let childrenCons = this.renderConnections.filter(
      e => nodeId == e.source.id
    );

    let lastXPosition = positionX;
    for (let i = 0; i < childrenCons.length; i++) {
      const childCon = childrenCons[i];

      lastXPosition = this.drawNodes(
        childCon.destination.id,
        lastXPosition + (i > 0 ? offsetX : 0),
        positionY + offsetY,
        offsetX,
        offsetY
      );
    }

    return lastXPosition;
  }

  rearrangeChart() {
    this.updateChartData();

    const chart = this.flowChart;
    const CHART_WIDTH = chart.width;
    const CHART_HEIGHT = chart.height;
    const DEFAULT_NODE_WITDH = 200;
    const DEFAULT_NODE_HEIGHT = 60;
    const NODE_PADDING_X = 10;
    const NODE_PADDING_Y = 30;

    // rearrage start and end nodes' postion
    for (let i = 0; i < this.renderNodes.length; i++) {
      let node = this.renderNodes[i];

      if (node.type === "start" || node.type === "end") {
        node.x =
          CHART_WIDTH / 2 - (node.width ? node.width : DEFAULT_NODE_WITDH) / 2;
        node.y = 20;
        node.height = 40;
      } else {
        node.width = DEFAULT_NODE_WITDH;
        node.height = DEFAULT_NODE_HEIGHT;
      }
    }
    // change all connections start from botton and end to top
    for (let i = 0; i < this.renderConnections.length; i++) {
      let con = this.renderConnections[i];

      con.source.position = "bottom";
      con.destination.position = "top";
    }

    // find start node
    const startNode = this.renderNodes.find(e => e.type === "start");

    let nextParentIds = [startNode.id];
    let nextY = startNode.y + DEFAULT_NODE_HEIGHT + NODE_PADDING_Y;
    let cons = this.renderConnections.filter(
      e => nextParentIds.indexOf(e.source.id) >= 0
    );

    // remove duplicate data of 'cons'
    cons = cons
      .map((e: any) => e.destination.id)
      .map((e: any, i, final) => final.indexOf(e) === i && i)
      .filter((e: any) => cons[e])
      .map((e: any) => cons[e]);

    this.drawNodes(
      startNode.id,
      50,
      50,
      DEFAULT_NODE_WITDH + NODE_PADDING_X,
      DEFAULT_NODE_HEIGHT + NODE_PADDING_Y
    );

    this.updateFlowChartHeight();
  }

  @Emit("rearrangeChart")
  emitRearrangeChart() {
    return this.rearrangeChart;
  }

  updateChartData() {
    let nodeTemp = Object.assign([], this.flowChart.internalNodes);
    let connectionTemp = Object.assign([], this.flowChart.internalConnections);

    this.renderNodes = nodeTemp;
    this.renderConnections = connectionTemp;
  }

  @Emit("updateChartData")
  emitUpdateChartData() {
    return this.updateChartData;
  }

  handleEditNode(node: any) {
    let editingNode: any = {};
    editingNode.name = node.name;
    editingNode.description = node.description;
    editingNode.value = node.value;
    editingNode.content =
      node.approvers && node.approvers[0] ? node.approvers[0].name : "";
    editingNode.rawNode = node;
    this.selectedEditingNode = editingNode;
  }

  updateFlowChartHeight() {
    this.updateChartData();
    let maxHeight = 0;
    for (let i = 0; i < this.renderNodes.length; i++) {
      let node = this.renderNodes[i];
      if (node.y > maxHeight - 200) {
        maxHeight = node.y;
      }
    }

    this.autoHeight = maxHeight + 250;
  }

  updated() {
    if (this.chartUpdateWaiting) {
      this.rearrangeChart();
      this.chartUpdateWaiting = false;
    }
  }

  mounted() {
    this.flowChart = this.$refs.chart as Vue & {
      internalNodes: any;
      width: any;
      height: any;
      remove: Function;
      editCurrent: Function;
    };

    this.renderNodes = this.inNodes;
    this.renderConnections = this.inConnections;

    this.emitGetCurrentNode();
    this.emitAddChart();
    this.emitRemoveChart();
    this.emitRearrangeChart();
    this.emitUpdateChartData();
  }
}
</script>

<style lang="scss" scoped>
.flowchart {
  font-size: 13px;
  width: 100% !important;
  min-height: 600px;
}
</style>
