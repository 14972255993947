<template>
  <div>
    <div class="app-title">
      <img alt="Vue logo" src="../assets/app-logo.png" />
      <div class="version">{{version}}</div>
    </div>

    <a-menu class="module-container" @click="handleSelectedModuleChanged" :defaultSelectedKeys="['1']" :openKeys.sync="openKeys" theme="dark" mode="inline">
      <a-sub-menu key="module">
        <a-menu-item v-for="(data, index) in modules" :key="index">
          <router-link :to="{ name: data.path }">{{ data.moduleName }}</router-link>
        </a-menu-item>
      </a-sub-menu>
    </a-menu>

    <div class="content">
      <router-view />
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

import { moduleConfigs } from '../configs/ApiConfig';

const moduleMenuName: string = 'module';

@Component
export default class MenuBar extends Vue {
  modules: any = moduleConfigs;
  openKeys: any = [moduleMenuName];

  selectedModule: any = null;
  selectedModuleSampleData: any = null;
  selectedModulePostSampleData: any = null;

  postBody: string = JSON.stringify({});
  patchBody: string = JSON.stringify({});

  version: string = process.env.VUE_APP_VERSION;

  handleSelectedModuleChanged(e: any) {
    this.selectedModule = this.modules[e.key];
    this.selectedModuleSampleData = this.modules[e.key].sampleData;
    this.selectedModulePostSampleData = this.modules[e.key].postSampleData;
  }
}
</script>

<style lang="scss" scoped>
$title-bar-background-color: white;
$page-margin-top: 112px;
$menu-width: 256px;

.content {
  margin-left: $menu-width;
  padding-top: $page-margin-top;
  height: 100vh;
  overflow-x: auto;
}

.version {
  font-size: 12px;
}

.app-title {
  position: fixed;
  z-index: 999;
  width: 100vw;
  height: $page-margin-top;
  font-size: 24px;
  text-align: left;
  font-family: monospace;
  padding: 12px 24px;
  background-color: $title-bar-background-color;
  border-bottom: solid 1px rgba(00, 00, 00, 0.26);
  grid-template-columns: 1fr auto;
  display: grid;
}

.module-container {
  position: fixed;
  padding-top: $page-margin-top;
  width: $menu-width;
  height: 100%;
  background: $title-bar-background-color;
  font-family: Verdana, Geneva, sans-serif;
}

.module-container ::v-deep .ant-menu-submenu-title {
  height: 0px !important;
}

.module-container .ant-menu-item {
  text-align: left;
  background: $title-bar-background-color;
}

.module-container .ant-menu-item a {
  color: black;
}

.ant-menu ::v-deep .ant-menu-sub {
  background: $title-bar-background-color !important;
  box-shadow: none !important;
}

.ant-menu ::v-deep .ant-menu-sub {
  background: $title-bar-background-color !important;
  box-shadow: none !important;
}

.ant-menu ::v-deep li .ant-menu-item {
  padding-left: 36px !important;
}

.ant-menu ::v-deep a:hover {
  background: none;
  color: #fb4c07 !important;
}

.ant-menu li {
  font-size: 13px;
  color: rgba(00, 00, 00, 0.87);
}

.ant-menu li ::v-deep .ant-menu-item-selected {
  background: none;
  color: #fb4c07;

  .router-link-active {
    color: #fb4c07 !important;
  }
}

.ant-menu ::v-deep .ant-menu-item-active {
  background: none;
  color: #fb4c07 !important;

  .router-link-active {
    background: none;
    color: #fb4c07 !important;
  }
}
</style>
