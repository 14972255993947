<template>
  <div>
    <div v-if="selectedModule" class="api-container">
      <div v-if="selectedModule" class="topic">
        {{ selectedModule.moduleName }}
      </div>
      <div class="header-function-panel" v-if="selectedModule.headerFunctions">
        <a-list item-layout="horizontal" :data-source="selectedModule.headerFunctions">
          <a-list-item slot="renderItem" slot-scope="item">
            <a-popconfirm
              v-if="item.confirmation"
              placement="right"
              :ok-text="item.confirmation.okText"
              :cancel-text="item.confirmation.cancelText"
              @confirm="callPostAPICore(item.postUrl, '', item.successMessage, item.failedMessage)"
            >
              <template slot="title">
                {{ item.confirmation.confirmMessage }}
              </template>
              <a-button :type="item.primary ? 'primary' : ''">{{ item.name }}</a-button>
            </a-popconfirm>
            <a-button v-if="!item.confirmation" :type="item.primary ? 'primary' : ''" @click="callPostAPICore(item.postUrl, '', item.successMessage, item.failedMessage)">{{
              item.name
            }}</a-button>
          </a-list-item>
        </a-list>
      </div>

      <div v-if="selectedModule && selectedModule.sampleData">
        <a-list v-if="selectedModule && selectedModule.sampleData" item-layout="horizontal" :data-source="selectedModuleSampleData">
          <a-list-item slot="renderItem" slot-scope="item">
            <a-descriptions bordered>
              <a-descriptions-item :label="item.title" :span="10">
                <div class="text-sample-data">{{ formatJSON(item.data) }}</div>
              </a-descriptions-item>
            </a-descriptions>
          </a-list-item>
        </a-list>
      </div>

      <div v-if="selectedModule && selectedModule.get">
        <a-table
          class="get-table"
          size="middle"
          :bordered="true"
          :pagination="false"
          :rowClassName="(record, index) => (index == tableRowSelectedIndex ? 'row-highlight' : '')"
          :columns="columns"
          :dataSource="moduleData"
          :customRow="customRow"
        />
      </div>
      <div v-if="selectedModule">
        <div v-if="selectedModule.layout == DEFAULT_LAYOUT">
          <a-collapse :bordered="false" expandIconPosition="left" :activeKey="collapseActives">
            <a-collapse-panel class="collapse-header" v-if="selectedModule.post" header="POST" key="post-collapse">
              <a-icon slot="extra" type="snippets" class="get-code-sample-icon" @click="onGetCodeSampleClicked" />
              <div v-if="selectedModule.post.showStringEscape">Script Formatter</div>
              <a-textarea v-model="stringEscapePost" v-if="selectedModule.post.showStringEscape" :autoSize="{ minRows: 5 }"></a-textarea>
              <div class="collapse-button">
                <a-button v-if="selectedModule.post.showStringEscape" type="primary" @click="onStringEscapePostClicked">Format</a-button>
              </div>
              <div>POST BODY</div>
              <a-textarea v-model="postBody" :autoSize="{ minRows: 5 }" @blur="onPostBodyBlur"></a-textarea>
              <div v-if="selectedModule.post.scriptVariableName">
                <CodeEditor :code.sync="postScriptBody" />
              </div>
              <div class="collapse-button">
                <a-button class="collapse-button" type="primary" @click="callPostAPI"> Create </a-button>
              </div>
            </a-collapse-panel>
            <a-collapse-panel class="collapse-header" v-if="selectedModule.patch" header="PATCH" key="patch-collapse">
              <div v-if="selectedModule.patch.showStringEscape">Script Formatter</div>
              <a-textarea v-model="stringEscapePatch" v-if="selectedModule.patch.showStringEscape" :autoSize="{ minRows: 5 }"></a-textarea>
              <div class="collapse-button">
                <a-button v-if="selectedModule.patch.showStringEscape" type="primary" @click="onStringEscapePatchClicked"> Format </a-button>
              </div>
              <div>PATCH BODY</div>
              <a-textarea v-model="patchBody" placeholder="Body input" :autoSize="{ minRows: 5 }" @blur="onPatchBodyBlur" />
              <div v-if="selectedModule.patch.scriptVariableName">
                <CodeEditor :code.sync="patchScriptBody" />
              </div>
              <div class="collapse-button">
                <a-button type="primary" @click="callPatchAPI">Save</a-button>
              </div>
            </a-collapse-panel>
          </a-collapse>
        </div>
        <div v-if="selectedModule.layout == FLOW_LAYOUT && selectedModule.flowUI">
          <RearrangeableFlowChartWithControl :selectedModule="selectedModule" :data="patchBody" :createdCallBack="callGetAPI" :updatedCallBack="callGetAPI" />
        </div>
        <div v-if="selectedModule.layout == SUMMARY_LAYTOUT">
          <Synchronizer :selectedModule="selectedModule" :queryObject="selectedRowData" :callBack="callGetAPI" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import axios from 'axios';
import jsec from 'jsesc';
import beautify from 'json-beautify';
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';

import RearrangeableFlowChartWithControl from '../components/RearrangeableFlowChartWithControl.vue';
import RearrangeableFlowChart from '../components/RearrangeableFlowChart.vue';
import CodeEditor from './CodeEditor.vue';
import Synchronizer from './Synchronizer.vue';

import { moduleConfigs } from '../configs/ApiConfig';
import { ModuleConfigModel } from '../configs/interfaces/IApiConfig';
import { NODE_ENV } from '../systems/configurations';

const postCollapseName: string = 'post-collapse';
const patchCollapseName: string = 'patch-collapse';

@Component({
  components: {
    RearrangeableFlowChartWithControl,
    RearrangeableFlowChart,
    CodeEditor,
    Synchronizer,
  },
})
export default class ApiCaller extends Vue {
  //#region authenticate
  async getAccessToken() {
    let accessToken = null;
    if (NODE_ENV !== 'development') {
      try {
        accessToken = await this.$auth.getTokenSilently();
      } catch (err) {
        if (err instanceof Error) {
          this.$notification['error']({
            message: err.message,
            description: '',
            placement: 'bottomRight',
          });
        }
      }
    }

    return accessToken;
  }
  //#endregion

  //#region constants
  DEFAULT_LAYOUT = 'DEFAULT';
  SUMMARY_LAYTOUT = 'SUMMARY';
  FLOW_LAYOUT = 'FLOW';
  //#endregion

  isAddFlowModalShown = false;
  addFlowType = '';
  addFlowArgs = '';
  addStepDesc = '';

  isEditFlowModalShown = false;
  editStepDesc = '';
  editStepScript: string | undefined = '';
  editFlowType: string = '';
  editFlowArgs: any = null;
  editingNode: any = null;

  @Prop()
  module: any;

  @Watch('module')
  onModuleChanged() {
    this.initialApiCaller();
  }

  beforeMount() {
    this.initialApiCaller();
  }

  initialApiCaller() {
    this.selectedModule = this.module;
    this.selectedModuleSampleData = this.module.sampleData;
    this.selectedModulePostSampleData = this.module.postSampleData;
    this.postBody = JSON.stringify({});
    this.patchBody = JSON.stringify({});

    this.tableRowSelectedIndex = -1;

    this.setDataTableColumns();

    if (this.selectedModule && this.selectedModule.get) {
      this.callGetAPI();
    }
  }

  selectedModule: ModuleConfigModel = moduleConfigs[0];
  selectedModuleSampleData: any = null;
  selectedModulePostSampleData: any = null;

  stringEscapePost: string = '';
  stringEscapePatch: string = '';

  postBody: string = JSON.stringify({});
  postScriptBody: string = '';

  updatePostScriptBody() {
    try {
      const postBodyObject = JSON.parse(this.postBody);

      if (this.selectedModule?.post?.scriptVariableName) {
        this.postScriptBody = postBodyObject[this.selectedModule.post.scriptVariableName];
      }
    } catch (err) {}
  }

  @Watch('postScriptBody')
  onPostScriptBodyChanged(val: any) {
    let postObject = JSON.parse(this.postBody);

    if (this.selectedModule?.post?.scriptVariableName) {
      postObject[this.selectedModule.post.scriptVariableName] = this.postScriptBody;

      this.postBody = this.formatJSON(JSON.stringify(postObject));
    }
  }

  patchBody: string = JSON.stringify({});
  patchScriptBody: string = '';

  updatePatchScriptBody() {
    try {
      const patchBodyObject = JSON.parse(this.patchBody);

      if (this.selectedModule?.patch?.scriptVariableName) {
        this.patchScriptBody = patchBodyObject[this.selectedModule.patch.scriptVariableName];
      }
    } catch (err) {}
  }

  @Watch('patchScriptBody')
  onPatchScriptBodyChanged(val: any) {
    let patchObject = JSON.parse(this.patchBody);

    if (this.selectedModule?.patch?.scriptVariableName) {
      patchObject[this.selectedModule.patch.scriptVariableName] = this.patchScriptBody;

      this.patchBody = this.formatJSON(JSON.stringify(patchObject));
    }
  }

  moduleData: any = null;
  moduleSampleData: any = null;
  getResData: any = null;
  columns: any[] = [];
  tableRowSelectedIndex = -1;

  selectedRow: any = -1;
  selectedRowData: any = null;

  collapseActives: any = [];

  customRow(record: any, index: any) {
    return {
      rowClassName: (record: any, index: any) => {
        if (this.selectedRow == index) {
          console.log(index);
        }
      },
      on: {
        click: () => {
          this.selectedRowData = record;
          try {
            const data = this.getResData.find((d: any) => {
              return d._id.toString() === record._id.toString();
            });
            delete data.key;
            delete data.createdDate;
            delete data.updatedDate;
            delete data.__v;

            try {
              this.patchBody = beautify(data, null, 4, 80);
              this.updatePatchScriptBody();

              this.tableRowSelectedIndex = index;
              this.openCollapse(patchCollapseName);
            } catch (err) {
              console.log(err);
            }
          } catch (err) {}
        },
      },
    };
  }

  openCollapse(collapseName: string) {
    if (this.collapseActives.indexOf(collapseName) >= 0) {
      this.collapseActives.splice(this.collapseActives.indexOf(collapseName), 1);
    }
    this.collapseActives.push(collapseName);
  }

  setDataTableColumns() {
    this.columns = [];

    if (!this.selectedModule || !this.selectedModule.get || !this.selectedModule.get.fields) {
      return;
    }

    for (let i = 0; i < this.selectedModule.get.fields.length; i++) {
      let field = this.selectedModule.get.fields[i];
      this.columns.push({
        title: field.name,
        dataIndex: field.field,
        width: field.width ? field.width : '',
        sorter: (a: any, b: any) => a[field.field].localeCompare(b[field.field]),
      });
    }
  }

  formatJSON(jsonString: string) {
    let json = JSON.parse(jsonString);
    return beautify(json, null, 4, 80);
  }

  onGetCodeSampleClicked(e: any) {
    if (this.selectedModulePostSampleData) {
      this.postBody = this.formatJSON(JSON.stringify(this.selectedModulePostSampleData));
    }
    e.stopPropagation();
  }

  onStringEscapePostClicked() {
    this.stringEscapePost = jsec(this.stringEscapePost, {
      json: true,
    });
  }

  onStringEscapePatchClicked() {
    this.stringEscapePatch = jsec(this.stringEscapePatch, {
      json: true,
    });
  }

  onPostBodyBlur() {
    this.postBody = this.formatJSON(this.postBody);
    this.updatePostScriptBody();
  }

  onPatchBodyBlur() {
    this.patchBody = this.formatJSON(this.patchBody);
    this.updatePatchScriptBody();
  }

  getKeyIndex(data: any) {
    for (let i = 0; i < data.length; i++) {
      data[i]['key'] = i;
    }
    return data;
  }

  formatData(value: any, formatFunc: Function | undefined) {
    if (formatFunc) {
      return formatFunc(value);
    }

    if (value instanceof Object || value instanceof Array) {
      return JSON.stringify(value);
    }

    return value;
  }

  serializeDataTableObject(data: any[]) {
    let returnData: any[] = [];

    for (let i = 0; i < data.length; i++) {
      let temp = Object.assign({}, data[i]);
      if (!this.selectedModule || !this.selectedModule.get || !this.selectedModule.get.fields) {
        return;
      }

      for (let j = 0; j < this.selectedModule.get.fields.length; j++) {
        let field: string = this.selectedModule.get.fields[j].field;
        const formatFunc = this.selectedModule.get.fields[j].formatFunc;

        let dataKey = '';

        if (this.selectedModule.get.fields[j].dataKey) {
          dataKey = this.selectedModule.get.fields[j].dataKey ?? '';
        } else {
          dataKey = this.selectedModule.get.fields[j].field;
        }

        temp[field] = this.formatData(data[i][dataKey], formatFunc);
      }

      returnData.push(temp);
    }
    return returnData;
  }

  async callGetAPI() {
    if (!this.selectedModule.get || !this.selectedModule.get.url) {
      return;
    }

    this.getResData = [];
    this.moduleData = [];

    const accessToken = await this.getAccessToken();

    try {
      const res = await axios.get(
        this.selectedModule.get.url,
        accessToken
          ? {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          : undefined
      );
      if (res.data && res.data.length) {
        this.getResData = this.getKeyIndex(res.data);
        this.moduleData = this.serializeDataTableObject(this.getResData);
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        this.$notification['error']({
          message: err.message,
          description: '',
          placement: 'bottomRight',
        });
      }
    }
  }

  callPostAPI() {
    if (!this.selectedModule || !this.selectedModule.post) {
      return;
    }

    this.callPostAPICore(this.selectedModule.post.url, JSON.parse(this.postBody), 'Created data successfully', 'Creating data failed');
  }

  async callPostAPICore(url: string, postbody: any, successMessage: string, errorMessage: string) {
    const accessToken = await this.getAccessToken();

    try {
      const res = await axios.post(
        url,
        postbody,
        accessToken
          ? {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          : undefined
      );

      this.callGetAPI();
      this.$notification['success']({
        message: successMessage,
        description: '',
        placement: 'bottomRight',
      });
    } catch (err) {
      if (axios.isAxiosError(err)) {
        this.$notification['error']({
          message: errorMessage,
          description: err.response?.data?.message ?? err.message,
          placement: 'bottomRight',
        });
      }
    }
  }

  async callPatchAPI() {
    const accessToken = await this.getAccessToken();

    try {
      if (!this.selectedModule || !this.selectedModule.patch) {
        return;
      }

      const res = await axios.patch(
        this.selectedModule.patch.url,
        JSON.parse(this.patchBody),
        accessToken
          ? {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          : undefined
      );

      this.callGetAPI();
      this.$notification['success']({
        message: 'Updated data successfully',
        description: '',
        placement: 'bottomRight',
      });
    } catch (err) {
      if (axios.isAxiosError(err)) {
        this.$notification['error']({
          message: 'Updating data failed',
          description: err.response?.data?.message ?? err.message,
          placement: 'bottomRight',
        });
      }
    }
  }

  @Watch('openKeys')
  onOpenKeysChanged(val: any) {
    console.log('openKeys', val);
  }
}
</script>

<style lang="scss" scoped>
$title-bar-background-color: white;
$page-margin-top: 115px;

$menu-width: 256px;

.get-code-sample-icon {
  font-size: 20px;
}

.text-sample-data {
  text-align: left;
  white-space: pre-wrap;
}

.get-table {
  margin-bottom: 16px;
}

.get-table ::v-deep .row-highlight {
  background-color: #4d6990;
  color: white;
}

.get-table ::v-deep .row-highlight:hover {
  color: rgba(00, 00, 00, 0.74);
}

.get-table ::v-deep .row-active {
  background-color: #4d6990;
  color: white;
}

.get-table ::v-deep .ant-table-body {
  font-family: Verdana, Geneva, sans-serif;
  font-size: 12px;
}

.topic {
  margin: 12px 8px;
  text-align: left;
  font-size: 24px;
  font-family: Verdana, Geneva, sans-serif;
}

.header-function-panel {
  margin-top: 12px;
  margin-bottom: 12px;
  text-align: left;
}

.collapse-header {
  text-align: left;
  font-weight: bold;
}

.collapse-button {
  text-align: left;
  margin-top: 4px;
  margin-bottom: 12px;
  margin-right: 8px;
}

.api-container {
  padding: 20px 35px;
}

.ant-carousel ::v-deep .custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}

.ant-carousel ::v-deep .custom-slick-arrow:before {
  display: none;
}

.ant-carousel ::v-deep .custom-slick-arrow:hover {
  opacity: 0.5;
}

.ant-carousel ::v-deep .slick-slide {
  padding: 30px 100px;
  text-align: left;
  height: 300px;
  line-height: 22px;
  background: #364d79;
  overflow: hidden;
  overflow-y: auto;
}

.ant-carousel ::v-deep .slick-slide ::-webkit-scrollbar-thumb {
  width: 5px;
}

.ant-carousel ::v-deep .slick-slide pre {
  color: white;
}

// flow container
.flow-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.ant-input {
  font-family: monospace;
  line-height: 160%;
}

.ant-divider {
  background: rgba(0, 0, 0, 0.3) !important;
}
</style>
